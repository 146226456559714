$(function () {
    function slideMenuMobile(parent) {
        parent.on('click', function () {
            if ($(this).hasClass('open')) {
                $(this).removeClass('open').children('ul').slideUp();
            } else {
                $(this).addClass('open').children('ul').slideDown();
                return false;
            }
        }).mouseleave(function () {
            $(this).removeClass('open').children('ul').slideUp();
        });
    }
    function slideMenuDesktop(parent) {
        parent.mouseenter(function () {
            if (!$(this).children('ul').is(":animated")) {
                $(this).addClass('open');
            }
        }).mouseleave(function () {
            $(this).removeClass('open');
        });
    }

    var $ul = $('.header').find('ul'),
        $drop = $('.nav-dropdown'),
        $li = $ul.find('li'),
        parent = $ul.find('li:has(ul)');

    parent.addClass('parent');

    //Current Menu
    uplvl = $ul.find('li:has(.active)');
    uplvl.addClass('active');

    $('.menu-toggle').on('click', function () {
        var $this = $(this);

        if ($this.hasClass('toggle-active')) {
            $this.removeClass('toggle-active');
            $('.menu').removeClass('menu-active');
            $('#header').removeClass('open--mobile-menu');
        } else {
            $this.addClass('toggle-active');
            $('.menu').addClass('menu-active');
            $('#header').addClass('open--mobile-menu');
            return false;
        }
    });

    //選單下展
    if (/android/i.test(navigator.userAgent) || /iphone/i.test(navigator.userAgent)) {
        //for mobile
        slideMenuMobile(parent)
        slideMenuMobile($drop)

    } else {
        //for pc
        slideMenuDesktop(parent)
        slideMenuDesktop($drop)
    }
});

