import WOW from 'wow.js'

// Some JavaScript Here
$(document).ready(function(){

  //index sidebar's dropdown animation
  $('.drop').click(function(){
    $(this).siblings('.dropdown-menu').slideToggle();
  });


  //static sidebar's dropdown animation
  $('.static-drop').click(function(){
    $('.static-dropdown-menu').slideToggle();
  });
  $('.left-nav-item > a').click(function(){
    event.preventDefault();
    $(this).parent().siblings().find('ul').slideUp();
    $(this).parent().find('ul').slideToggle();
    $(this).parent().addClass('open').siblings().removeClass('open');
  });

  //scroll to top animation
  $('.back-to-top').click(function(){
    $("html,body").animate({scrollTop: 0}, 1000);
  });

  //brand page tabpanel
  $('.nav-tabs li').click(function(){
    event.preventDefault();
    $(this).addClass('active').siblings().removeClass('active');
  });
  $('.content:first').show();
  $('.nav-tabs li:first').addClass('active');

  $('.tab-news').click(function(){
    event.preventDefault();
    $('.content-1').fadeIn(500).siblings().hide();
  });
  $('.tab-scuccess').click(function(){
    event.preventDefault();
    $('.content-2').fadeIn(500).siblings().hide();
  });
  $('.tab-technical').click(function(){
    event.preventDefault();
    $('.content-3').fadeIn(500).siblings().hide();
  });
  $('.tab-product').click(function(){
    event.preventDefault();
    $('.content-4').fadeIn(500).siblings().hide();
  });
  $('.tab-class').click(function(){
    event.preventDefault();
    $('.content-5').fadeIn(500).siblings().hide();
  });
  $('.tab-class').click(function(){
    event.preventDefault();
    $('.content-6').fadeIn(500).siblings().hide();
  });

  //brand static panel
  $('.panel-group > li > a').click(function(){
    event.preventDefault();
    // $(this).toggleClass('active');
    $(this).parent().siblings().find('ul').slideUp();
    $(this).parent().find('ul').slideToggle();
  });

  //wow.js animation plugin
  new WOW().init();

  //lightbox
  $('#open-modal').click(function(){
    $('#myModal').show();
  });
  $('#closeModal').click(function(){
    $('#myModal').css('display','none');
  });

  $('#sustainable_cancel').click(function(){
    $("#sustainable_popup").addClass('hide');
  });
})
