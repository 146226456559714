import Cookies from 'js-cookie';
import 'core-js';
import 'public/main'
import 'public/style.scss'
import 'public/js/jquery.easing.1.3.js'
import 'public/js/jquery.colorbox.js'
import 'public/js/flexslider/flexslider_main.js'
import 'public/base';
import ahoy from "ahoy.js"
import Rails from "@rails/ujs"
Rails.start();

window.jQuery = $;
window.$ = $;
window.Cookies = Cookies;

