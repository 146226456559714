$(function () {
    function slideLeftMenuMobile(parent) {
        parent_link.on('click', function () {
            if ($(this).parent(parent).hasClass('open')) {
                $(this).parent(parent).removeClass('open').children('ul').slideUp();
            } else {
                $(this).parent(parent).addClass('open').children('ul').slideDown();
                return false;
            }
        });
    }

    var $ul = $('.left-side').find('ul'),
        $li = $ul.find('li'),
        parent = $ul.find('li:has(ul)'),
        parent_link = $('.left-side .sidebar-link');

    parent.addClass('parent');

    //Current Menu
    uplvl = $ul.find('li:has(.current-menu)');
    uplvl.addClass('current-menu');

    //選單下展
    slideLeftMenuMobile(parent)

    $(".control").click(function () {
        if ($(this).hasClass("active")) {
            $(this).toggleClass("active");
            $(this).parents(".left-side").toggleClass("active");
            // $(".overlay").fadeOut();
        } else {
            $(this).toggleClass("active");
            $(this).parents(".left-side").toggleClass("active");
            // $(".overlay").fadeIn();
        }
    })
});

