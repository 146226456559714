// import AOS from 'aos';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs } from 'swiper';
import 'public/js/header.js'
import 'public/js/footer.js'
import 'public/js/left-side/left-side.js'




Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {

  var pageName = $(".frame-outer .zone").data("page");
  var activeHeader = $("[data-menu=" + pageName +"]");
  if (pageName) {
    activeHeader.addClass('active');
  }

  var swiper = new Swiper(".mainvision-swiper", {
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    navigation: {
      nextEl: '.mainvision-swiper .swiper-button-next',
      prevEl: '.mainvision-swiper .swiper-button-prev'
    },
    autoplay: {
      delay: 5000,
    },
    slidesPerView: "auto",
    centeredSlides: false,
    spaceBetween: 0,
    initialSlide: 0,
    loop: true,
    speed: 800,
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: true,
    },
  });

  var swiper = new Swiper(".news-swiper", {
    // navigation: {
    //   nextEl: '.news-swiper .swiper-button-next',
    //   prevEl: '.news-swiper .swiper-button-prev'
    // },
    slidesPerView: "auto",
    centeredSlides: false,
    spaceBetween: 0,
    initialSlide: 0,
    loop: true,
    speed: 1000,
    direction: 'vertical',
    autoHeight: true,
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: true,
    },
  });

});
